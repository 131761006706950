import { useNavigate } from "react-router-dom";
import Footer from "../partials/Footer";

const WhatYouNeedToKnowAccordion = ({ setCurrentScreen }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container mt-lg-5 mt-3 mb-5 accordion-font">
        <div className="text-center mb-3 mb-md-5">
          <div>
            <img
              src="../assets/images/logo.png"
              alt=""
              className="logo-image-width mb-2"
            />
          </div>
          <div>
            <h2>PENIEL ACADEMY</h2>

            <h4>Welcome to Peniel Admission Portal</h4>
            <button
              type="button"
              className="btn btn-success my-auto w-25"
              onClick={() => navigate("/registration")}
            >
              Click to Begin Admission Process
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <h4>What You Need To Know (Parent)</h4>
        </div>
        <div className="accordion" id="accordionExample">
          <div className="card m-0">
            <div className="card-header" id="headingOne">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  TEXT BOOKS/ LEARNING MATERIALS
                </button>
              </h2>
            </div>

            <div
              id="collapseOne"
              className="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    All prescribed and approve textbooks must be bought once
                    admission has been granted in order to encourage learning
                    (all subjects are equally important; the ACADEMIC UNIT
                    strongly prohibits parents from getting certain subjects
                    text books over others). Approved learning materials are
                    available at the schools’ book store.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="headingTwo">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  EXERCISES
                </button>
              </h2>
            </div>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    Exercises are given and marked for every subject in a day.
                    Parents should note help their wards pack the appropriate
                    exercise book and text books to school daily, in accordance
                    to the class time table.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="headingThree">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  CLASSTEST
                </button>
              </h2>
            </div>
            <div
              id="collapseThree"
              className="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    Class test are regularly done at least twice in a month.
                    Please ensure punctuality of your wards in school. All
                    exercises form an integral part of the SBA and end of term
                    results for Term1 and Term 2.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="heading4">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse4"
                  aria-expanded="false"
                  aria-controls="collapse4"
                >
                  HOMEWORK/ASSIGNMENT
                </button>
              </h2>
            </div>
            <div
              id="collapse4"
              className="collapse"
              aria-labelledby="heading4"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    Assignments are given every day. Parents should expect at
                    least 4 sets of homework daily for learners in classes
                    1-JHS3. Nursery 1 and 2 learners are given at least 2
                    homework daily. All assignments should be vetted by parents
                    by signing.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="heading5">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse5"
                  aria-expanded="false"
                  aria-controls="collapse5"
                >
                  ON STUDENTS SUPPORT SERVICE
                </button>
              </h2>
            </div>
            <div
              id="collapse5"
              className="collapse"
              aria-labelledby="heading5"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    Peniel Support Service is a holistic academic and
                    socio-psychological programme targeted at students with
                    specific learning and social integration challenges. The
                    support service is a guided “part time” service. The
                    programme is only eligible to students who have been
                    successfully profiled and diagnosed with academic
                    challenges.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="heading6">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse6"
                  aria-expanded="false"
                  aria-controls="collapse6"
                >
                  ON EXAMINATIONS
                </button>
              </h2>
            </div>
            <div
              id="collapse6"
              className="collapse"
              aria-labelledby="heading6"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    We hold the sanctity of examinations in high regard. We
                    disdain any form of examination malpractices and examination
                    leakages. The school and teaching staff holds ourselves to
                    higher standards of examination conduct and as such we
                    insist on the same conduct for our leaners. Any learner
                    caught engaging in any examination malpractice would be
                    dismissed.
                  </li>
                  <li>
                    Learners are promoted to their next on the attaining an
                    average of 60% from basic 4 to JHS, and 50% from KG1 to BS 3
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="heading7">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse7"
                  aria-expanded="false"
                  aria-controls="collapse7"
                >
                  ON TERMINAL REPORT
                </button>
              </h2>
            </div>
            <div
              id="collapse7"
              className="collapse"
              aria-labelledby="heading7"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    Parents can acess their ward(s) academic and psycho-social
                    report
                    <a
                      href="https://peniel.schoolerpghana.com"
                      target="noreferrer"
                      className="ms-1"
                    >
                      https://peniel.schoolerpghana.com{" "}
                    </a>{" "}
                    portal. As they log in the details of their wards. Your
                    wards username and password would be given after entrance
                    examinations have been written.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="heading8">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse8"
                  aria-expanded="false"
                  aria-controls="collapse8"
                >
                  ON INFORMATION DISSEMINATION
                </button>
              </h2>
            </div>
            <div
              id="collapse8"
              className="collapse"
              aria-labelledby="heading8"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    All school official information is sent via PENIEL SMS,
                    PENIEL WEBSITE, PENIEL SCHOOL PORTAL, OFFICIAL SCHOOL
                    WHATSAPP GROUPS , AND NOTICE BOARD(voice notes in English
                    and Twi).
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="heading9">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse9"
                  aria-expanded="false"
                  aria-controls="collapse9"
                >
                  ON FEES
                </button>
              </h2>
            </div>
            <div
              id="collapse9"
              className="collapse"
              aria-labelledby="heading9"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    School fees are billed for every term. Parents are required
                    to pay at least 60% fees on reopening and 40% within the
                    first four weeks of reopening.
                  </li>
                  <li>
                    Feeding fee/ Canteen fee are compulsory payments. Parents
                    can choose to pay daily, weekly and monthly.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="heading10">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse10"
                  aria-expanded="false"
                  aria-controls="collapse10"
                >
                  ON NON FEE PAYMENT BILLS
                </button>
              </h2>
            </div>
            <div
              id="collapse10"
              className="collapse"
              aria-labelledby="heading10"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    All school approved bills will be sent to parents via SMS.
                    Students pay 10 cedis as weekly charge for extra tution (
                    N1-BS6 ) and 17 cedis for JHS 1-JHS 3
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="heading12">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse12"
                  aria-expanded="false"
                  aria-controls="collapse12"
                >
                  ON REPORTING TIME AND CLOSING TIME
                </button>
              </h2>
            </div>
            <div
              id="collapse12"
              className="collapse"
              aria-labelledby="heading12"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    classes starts at exactly 7oclock Am and ends at 3:00am for
                    preschool to class 6 And 4:30 for JHS
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="heading13">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse13"
                  aria-expanded="false"
                  aria-controls="collapse13"
                >
                  ON DRESSING
                </button>
              </h2>
            </div>
            <div
              id="collapse13"
              className="collapse"
              aria-labelledby="heading13"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    We insist on orderliness and neatness. All uniforms should
                    be paired with a pure white socks. We believe in modesty
                    hence short and tight uniforms for both genders are not
                    allowed.
                  </li>
                  <li>
                    The appropriate uniform should be worn daily otherwise
                    students would be punished
                  </li>
                  <li>
                    Boys and girls should groom their hair decently without and
                    any pop or high fashioned hairstyle. All hairstyle should be
                    age appropriate and neat. Hairstyles like dreadlocks on
                    learners are not allowed.
                  </li>
                  <li>
                    Unannounced inspections are done to ensure students are
                    always kempt and neat.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="heading14">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse14"
                  aria-expanded="false"
                  aria-controls="collapse14"
                >
                  ON PUNISHMENT
                </button>
              </h2>
            </div>
            <div
              id="collapse14"
              className="collapse"
              aria-labelledby="heading14"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    Should a learner falter on the school’s ethics, he or she
                    would be punished under the supervision of the headmaster.
                    We believe in the guided use of the rod in correcting the
                    child as pointed out in proverbs 13:24. Again punishment may
                    include; internal suspension, external suspension.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="heading15">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse15"
                  aria-expanded="false"
                  aria-controls="collapse15"
                >
                  ON VACATION
                </button>
              </h2>
            </div>
            <div
              id="collapse15"
              className="collapse"
              aria-labelledby="heading15"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>The last day of examination is the day of vacation</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="heading16">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse16"
                  aria-expanded="false"
                  aria-controls="collapse16"
                >
                  ON RELIGION AND BELIEFS
                </button>
              </h2>
            </div>
            <div
              id="collapse16"
              className="collapse"
              aria-labelledby="heading16"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    Peniel is a Christian school that’s based on Christian
                    principles. As such, we conduct compulsory Wednesday worship
                    from 7:00-8:00am as well as compulsory church service for
                    boarders in JHS 3. No form of worldly or secular music are
                    played or sung within the school premises.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="heading17">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse17"
                  aria-expanded="false"
                  aria-controls="collapse17"
                >
                  ON BOARDING FACILITY
                </button>
              </h2>
            </div>
            <div
              id="collapse17"
              className="collapse"
              aria-labelledby="heading17"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    Our school operates a compulsory semi boarding facility for
                    all students in JHS 3
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="heading18">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse18"
                  aria-expanded="false"
                  aria-controls="collapse18"
                >
                  ON BIRTHDAY CELEBRATIONS
                </button>
              </h2>
            </div>
            <div
              id="collapse18"
              className="collapse"
              aria-labelledby="heading18"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>
                    Birthday celebrations are disallowed within the school
                    premise. Sharing of drinks, toffees and other gifts by
                    parents to a class is STRICTLY prohibited
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card m-0">
            <div className="card-header" id="heading19">
              <h2 className="mb-0">
                <button
                  className="btn btn-link btn-block text-left collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapse19"
                  aria-expanded="false"
                  aria-controls="collapse19"
                >
                  SCHOOL FACILITIES
                </button>
              </h2>
            </div>
            <div
              id="collapse19"
              className="collapse"
              aria-labelledby="heading19"
              data-parent="#accordionExample"
            >
              <div className="card-body p-md-4 p-2">
                <ul>
                  <li>IT center for lower primary</li>
                  <li>ART CENTER for lower primary</li>
                  <li>IT CENTER for upper primary and JHS</li>
                  <li>SCIENCE LABORATORY</li>
                  <li>E-LIBRARY</li>
                  <li>DINNING HALL</li>
                  <li>CONTACT HOURS SPECIFIC INTERNET ACESS</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-2">
          <button
            type="button"
            className="btn btn-success my-auto w-25 text-center"
            onClick={() => navigate("/registration")}
          >
            Click to Begin Admission Process
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default WhatYouNeedToKnowAccordion;

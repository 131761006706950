import React, { useEffect, useState } from "react";
import parse from 'html-react-parser';
import convertDate from "../../components/helpers/ConverDate";

const AdmissionForm = ({ data }) => {
  const [details,setDetails]=useState();
console.log(data);
  useEffect(()=>{
    setDetails(data);
  },[data])
  
  return (
    <div className="col-lg-12">
      <div className="card card-table table-height3">
        <div className="card-body booking_card">
          <div className="head-cover text-center">
            <div>
              {parse(data?.school?.resource?.header || '<h3>Loading</h3>') }
            </div>
            <div className="img-position">
              <img
                className="print-img" width={'100%'}
                src={`${process.env.REACT_APP_BACKEND_URL}/${data?.school?.image}`}
                alt=""
              />
            </div>
            <div className="admiss">
              <b>ADMISSION FORM</b>
            </div>
            <div align="right">
              <div className="wrapper1">
                <img src={`${process.env.REACT_APP_BACKEND_URL}/${details?.image}`} id="photo" alt="" />
              </div>
            </div>
          </div>
          <div className="body-content">
            <div>
              <h3>Personal Information</h3>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  First Name : <span className="text-capitalize">{data?.firstname}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Other Name : <span className="text-capitalize">{data?.othername}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Other Name : <span className="text-capitalize">{data?.lastname}</span>
                </h4>
              </div>
              
            </div>
            <div className="div-content">
            <div className="div-content-2">
                <h4>
                  Gender : <span className="text-capitalize">{data?.gender}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Date Of Birth : <span className="text-capitalize">{details?.date_of_birth}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Place Of Birth : <span className="text-capitalize">{details?.place_of_birth}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
            <div className="div-content-2">
                <h4>
                  Hometown : <span className="text-capitalize">{details?.hometown}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Nationality : <span className="text-capitalize">{details?.nationality}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Residence : <span className="text-capitalize">{details?.residence}</span>
                </h4>
              </div>
             
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Residence : <span className="text-capitalize">{details?.residence}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  NHIS Number : <span className="text-capitalize">{details?.nhis}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Religion : <span className="text-capitalize">{details?.religious_denomination}</span>
                </h4>
              </div>
            </div>
            <div className="mt-5">
              <h3>School Information</h3>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Name of Last School : <span>{details?.prev_school}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Reason for Leaving : <span className="text-capitalize">{data?.reason_for_leaving_prev_school}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
            <div className="div-content-2">
                <h4>
                  Class : <span className="text-capitalize">{details?.class}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Admission Date : <span className="text-capitalize">{convertDate(data?.created_at).substring(0,10)}</span>
                </h4>
              </div>
            </div>
           
            <div className="mt-3">
              <h3>Parents Information</h3>
            </div>
            <div>
              <h4>Father</h4>
            </div>
            <div className="div-content-cover">
              <div className="div-content">
                <div className="div-content-2">
                  <h4>
                    Full Name : <span className="text-capitalize"> {details?.father_name}</span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Nationality : <span className="text-capitalize"> {details?.father_nationality}</span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Hometown : <span className="text-capitalize">{details?.father_hometown}</span>
                  </h4>
                </div>
              </div>
              <div className="div-content">
                <div className="div-content-2">
                  <h4>
                    Occupation : <span className="text-capitalize"> {details?.father_occupation}</span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Contact : <span className="text-capitalize">{details?.father_phone}</span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Is father Alive? : <span className="text-capitalize">{details?.father_alive}</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <h4>Mother</h4>
            </div>
            <div className="div-content-cover">
              <div className="div-content">
                <div className="div-content-2">
                  <h4>
                    Full Name : <span className="text-capitalize">{details?.mother_name}</span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Nationality : <span className="text-capitalize">{details?.mother_nationality}</span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Hometown : <span className="text-capitalize">{details?.mother?.hometown}</span>
                  </h4>
                </div>
              </div>
              <div className="div-content">
                <div className="div-content-2">
                  <h4>
                    Occupation : <span className="text-capitalize">{details?.mother_occupation}</span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Contact : <span>{details?.mother_phone}</span>
                  </h4>
                </div>
                <div className="div-content-2">
                  <h4>
                    Is Mother Alive? : <span className="text-capitalize">{details?.mother_alive}</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <h3>Guardian Information</h3>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Full Name : <span className="text-capitalize">{details?.guardian_name}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Nationality : <span className="text-capitalize"> {details?.guardian_nationality}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Hometown : <span className="text-capitalize">{details?.guardian_hometown}</span>
                </h4>
              </div>
            </div>
            <div className="div-content">
              <div className="div-content-2">
                <h4>
                  Occupation : <span className="text-capitalize">{details?.guardian_occupation}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Contact : <span>{details?.guardian_phone}</span>
                </h4>
              </div>
              <div className="div-content-2">
                <h4>
                  Do You Stay Together? : <span>{details?.guardian_alive}</span>
                </h4>
              </div>
            </div>
            <div className="mt-3 float-end">
              <div className="line1"></div>
              <div className="text-center mt-3">
                <h4>Headmaster</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="body-content text-center mb-4">
            <h5>
              Developed by Samasoft Technologies (https://samasoftech.net / 0260798965)
            </h5>
            {/* <h4><a href='https://www.opencastgh.com' rel='noreferrer' target='_blank'>https://www.opencastgh.com</a></h4> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmissionForm;

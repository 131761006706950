import axios from "axios";
import React, { useState } from "react";
import Button from "../../../components/Button";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import Swal from "sweetalert2";


const PurchaseVoucher = ({ setPage }) => {
  const [isloading, setLoading] = useState(false);
  const [phone, setPhone] = useState();
  const [userInput, setUserInput] = useState({
    network: "",
    phone: "",
  });

  const [errors, setErrors] = useState();

  const handleUserInput = (e) => {
    setErrors("");
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleVoucherPurchase = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!isValidPhoneNumber(phone)) {
      window.alert("Please Enter a valid phone number");
    }

    axios
      .post("/api/v1/voucher/purchase", {
        network: userInput?.network,
        phone: phone,
      })
      .then((res) => {
        if (res?.data?.status === 200) {
          window.location.replace(res?.data?.data?.authorization_url);
        }else{
          Swal.fire({
            title: "Transaction Failed",
            text: "Error Processing Payment",
            icon: "success",
            confirmButtonColor: "#011535",
            confirmButtonText: "Okay",
          }).then(() => setPage("initial"));

        }
        console.log(res?.data);
      })
      .catch((err) => {
        console.log(err?.response);
        setLoading(false);
        if (err?.response?.status === 422) {
          setErrors(err?.response?.data?.errors);
        }
        if (err?.response?.status === 400) {
          alert(err?.response?.data?.error_msg);
        }
      })
      .then(() => setLoading(false));
  };

  return (
    <form onSubmit={handleVoucherPurchase}>
      <div className="formtype">
        <div className="form-group">
          <label htmlFor="">
            <h4>Select Network</h4>
          </label>
          <select
            className="form-control"
            value={userInput?.network}
            name="network"
            required
            onChange={handleUserInput}
          >
            <option value={""}>--Select Network--</option>
            <option className="text-capitalize" value={"MTN-GH"}>
              MTN-GH
            </option>
            <option className="text-capitalize" value={"MTN-GH"}>
              VODAFONE-GH
            </option>
            <option className="text-capitalize" value={"MTN-GH"}>
              AIRTEL-GH
            </option>
            <option className="text-capitalize" value={"MTN-GH"}>
              TIGO-GH
            </option>
          </select>
          <small className="text-danger">{errors?.school_id}</small>
        </div>
        <div className="form-group">
          <label htmlFor="">
            <h4>Phone Number</h4>
          </label>
          <PhoneInput
            country="GH"
            defaultCountry="GH"
            // countries={["GH"]}
            countries={["GH"]}
            value={phone}
            onChange={setPhone}
            className="form-control text-left"
          />
          <div className="text-danger">
            {phone
              ? isValidPhoneNumber(phone)
                ? ""
                : "Invalid phone number"
              : ""}
          </div>
          <small className="text-danger">{errors?.phone}</small>
        </div>

        <div className="mt-4">
          <Button
            cssClasses={"btn btn-success w-100 text-nowrap"}
            isloading={isloading}
            buttonText={"Proceed to Voucher Purchase"}
          />
        </div>

        <div className="form-group text-center">
          <button
            className="btn btn-secondary mt-2 text-center text-nowrap"
            onClick={() => setPage("initial")}
          >
            Go Back
          </button>
        </div>
      </div>
    </form>
  );
};

export default PurchaseVoucher;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import TopBar from "./TopBar";

const SideBar = () => {
  const links = [
    // {
    //   id: 1,
    //   title: "Houses",
    //   url: "/dashboard/houses",
    //   icon: "fas fa-home",
    //   hasChildren: false,
    //   funcCallback: () => {},
    // },
    // {
    //   id: 2,
    //   title: "Admission List",
    //   url: "/dashboard/admission-list",
    //   icon: "fa fa-list",
    //   hasChildren: false,
    //   funcCallback: () => {},
    // },
    {
      id: 0,
      title: "Resources",
      url: "/dashboard/resources",
      icon: "fas fa-upload",
      hasChildren: false,
      funcCallback: () => {},
    },
    // {
    //   id: 0,
    //   title: "Bulk Voucher Purchase",
    //   url: "/dashboard/bulk-voucher-purchase",
    //   icon: "fas fa-sharp fa-regular fa-money-bill",
    //   hasChildren: false,
    //   funcCallback: () => {},
    // },
    {
      id: 5,
      title: "Logout",
      url: "/",
      icon: "fas fa-sign-out-alt",
    },
  ];

  const location = useLocation();

  return (
    <>
      <TopBar />
      <div className={"sidebar"} id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li
                className={`${location.pathname === "/dashboard/" && "active"}`}
              >
                {" "}
                <Link to="/dashboard/">
                  <i className="fas fa-tachometer-alt"></i>
                  <span>Dashboard</span>
                </Link>{" "}
              </li>
              <li className="list-divider"></li>

              {links.map((link, index) => (
                <>
                  <li
                    className={`${location.pathname === link?.url && "active"}`}
                    key={index}
                  >
                    {link?.hasChildren ? (
                      <a
                        href={`#${link?.slug}`}
                        data-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        key={link?.id}
                      >
                        <i className={`${link?.icon}`}></i>
                        <span>{link?.title}</span>
                      </a>
                    ) : (
                      <Link
                        to={link?.url}
                        key={link?.id}
                        onClick={link?.funcCallback}
                      >
                        <i className={`${link?.icon}`}></i>
                        <span>{link?.title}</span>
                      </Link>
                    )}
                  </li>
                  <div className="collapse ms-3" id={link?.slug}>
                    {link?.children?.map((child, i) => (
                      <Link
                        key={i}
                        to={child?.url}
                        style={childMenu}
                        className={`${
                          location.pathname === child?.url && "active"
                        } child-nav`}
                      >
                        <i className={`${child?.icon}`} style={icon}></i>
                        <span style={childMenuSpan}>{child?.title}</span>
                      </Link>
                    ))}
                  </div>
                </>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;

const icon = {
  display: "inline-block",
  fontSize: "19px",
  lineHeight: "24px",
  textAlign: "left",
  verticalAlign: "middle",
  width: "20px",
  transition: "all 0.2s ease-in-out 0s",
};

const childMenu = {
  alignItems: "center",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "flex-start",
  padding: "12px 10px",
  position: "relative",
  transition: "all 0.2s ease-in-out 0s",
  color: "#333",
};
const childMenuSpan = {
  transition: "all 0.2s ease-in-out 0s",
  display: "inline-block",
  marginLeft: "10px",
  whiteSpace: "nowrap",
};

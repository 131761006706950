/* eslint-disable no-unused-vars */

import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import Instructions from "./verificationFormComponents/Instructions";
import Footer from "../partials/Footer";
import Nav from "../partials/Nav";
import VerificationForm from "./VerificationForm";

const Verification = ({ setStudent, schools, setScreen }) => {


  const [amount, setAmount] = useState("");
 
  const handlePaymentProcessing = (value) => {
    axios
      .post("/api/transaction/donation/payment", { amount: value })
      .then((res) => {
        // console.log(res?.data);
        if (res?.data?.success === true && res?.data?.status === 200) {
          window.location.replace(res?.data?.data?.original?.data?.checkoutUrl);
        }
      })
      .catch((err) => {
        // setSubmitting(false);
        Swal.fire({
          title: "Trasaction Failed",
          text: "Error Processing Payment, Please Try Again Later",
          icon: "error",
        });
      });
  };

  const handleDonation = () => {
    Swal.fire({
      title: "Enter Amount",
      input: "text",
      inputValue: amount,
      showCancelButton: true,
      confirmButtonText: "Donate",
      confirmButtonColor: "#0061FF",
      inputValidator: (value) => {
        if (!value) {
          return "Please enter an amount!";
        } else if (value <= 0) {
          return "Please enter an amount geater than 0!";
        } else if (!Number(value)) {
          return "Amount must be a number and graeter than 0!";
        }
      },
    }).then((result) => {
      if (result.value) {
        setAmount(result.value);
        Swal.fire({
          title: `Amount: GHS${result.value}`,
          text: "Processing Please Wait...",
          icon: "success",
          timer: 15000,
        });
        handlePaymentProcessing(result.value);
        // Further processing code goes here...
      }
    });
  };

  return (
    <div>
      <Nav />

      <>
        {/* <Donation handleDonation={handleDonation} /> */}
        <div className="container-fluid advert-margin">
          <div className="d-md-flex">
            <Instructions />

            <VerificationForm
              setStudent={setStudent}
              schools={schools}
              setScreen={setScreen}
            />
          </div>
        </div>

        <Footer />
      </>
    </div>
  );
};

export default Verification;

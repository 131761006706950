import React, { useEffect, useRef } from "react";

function Instructions() {
  const slideInLeftRef = useRef(null);

  useEffect(() => {
    slideInLeftRef.current.classList.add("active");
  }, []);

  return (
    <div className="col-md-7 slide-in-left my-auto" ref={slideInLeftRef}>
      <div className="">
        <h2 className="mt-2 ">Admission Process </h2>
        <ol>
          <li>Click on "Purchase Admission Form" to make payment for admission form</li>
          <li>After Payment completion, Click on "Fill Registration Form"</li>
          <li>Enter the Admission Code Recieved via SMS after the payment completion together with the phone number used for the payment then click verify</li>
          <li>Fill admission forms by providing the required data</li>
          <li>Print all printables</li>
        </ol>
      </div>
    </div>
  );
}

export default Instructions;

import React, { useState, useEffect } from "react";
import Button from "../../components/Button";
import axios from "axios";
import Swal from "sweetalert2";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

const Declaration = ({ resource }) => {
  const [value, setValue] = useState(resource?.toString());
  const [isloading, setLoading] = useState(false);

  useEffect(() => {
    setValue(resource);
  }, [resource]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/api/v1/school/resource/create", {
        declaration: value,
        school_id: localStorage.getItem("school_id"),
      })
      .then((res) => {
        Swal.fire("Save", "Data has been saved successfully", "success");
      })
      .catch((err) => {
        console.log(err?.response);
        Swal.fire("Failed", "Error Saving Data", "error");
      })
      .finally((_) => {
        setLoading(false);
      });
  };

  return (
    <div className="card">
      <div className="card-header" id="headingOne">
        <div className="d-flex justify-content-between mb-2">
          <button
            className="btn btn-link btn-block text-left"
            type="button"
            data-toggle="collapse"
            data-target="#declaration"
            aria-expanded="false"
            aria-controls="declaration"
          >
            Consentual Agreement
          </button>

          <Button
            cssClasses={"btn btn-success"}
            buttonText={"Save Changes"}
            isloading={isloading}
            callback={handleSubmit}
          />
        </div>

        <div
          id="declaration"
          className="collapse "
          aria-labelledby="declaration"
          data-parent="#accordionExample"
        >
          <div className="card-body">
            {/* <ReactQuill
              theme="snow"
              style={{ height: "300%" }}
              value={value}
              onChange={setValue}
            /> */}
            <SunEditor
              setContents={value}
              setOptions={{
                buttonList: [
                  // default
                  ["undo", "redo"],
                  ["bold", "underline", "italic", "list",'align'],
                  ["font", "fontSize", "italic", "list",'fontColor'],
                  ["table", "horizontalRule","link",'indent'],
                  ["formatBlock","paragraphStyle","lineHeight","fullScreen"],
                
                  
                  
                  // "blockquote",
                  // "bold",
                  // "underline",
                  // "italic",
                  // "strike",
                  // "subscript",
                  // "superscript",
                  // "fontColor",
                  // "hiliteColor",
                  // "textStyle",
                  // "removeFormat",
                  // "outdent",
                  // "indent",
                  // "align",
                  // "horizontalRule",
                  // "list",
                  // "lineHeight",
                  // "table",
                  // "link",
                  // "image",
                  // "video",
                  // "audio",
                  // "math",
                  // "imageGallery",
                  // "fullScreen",
                  // "showBlocks",
                  // "codeView",
                  // "preview",
                  // "print",
                  // "save",
                  // "template",
                ],
              }}
              onChange={(value) => setValue(value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Declaration;

import React from "react";
import parse from "html-react-parser";

const Propspectus = ({ data }) => {
  var Prospectus = parse(data?.school?.resource?.admission_requirements || "");

  return (
    <div className="col-lg-12">
      <div className="card card-table table-height3">
        <div className="card-body booking_card">
          <div className="head-cover text-center">
            <div>
              {parse(data?.school?.resource?.header || "<h3>Loading...</h3>")}
            </div>
            <div className="img-position">
              <img
                className="print-img img-fluid"
                src={`${process.env.REACT_APP_BACKEND_URL}/${data?.school?.image}`}
                alt=""
              />
            </div>
            <div className="admiss2 text-nowrap">
              <b>
                Compulsory Admission <span>Requirements</span>
              </b>
            </div>
          </div>
          <div className="body-content">{Prospectus}</div>
        </div>
        <div className="col-lg-12">
          <div className="body-content text-center mb-4">
            <h5>
              Developed by Samasoft Technologies (https://samasoftech.net /
              0260798965)
            </h5>
            {/* <h4><a href='https://www.opencastgh.com' rel='noreferrer' target='_blank'>https://www.opencastgh.com</a></h4> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Propspectus;

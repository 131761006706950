import axios from "axios";
import { useEffect, useState } from "react";
import Button from "../../components/Button";

const ParentsInformation = ({ setScreen, student, setStudent }) => {
  const [isloading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState(student);
  const [guardian, setGuardian] = useState("");

  useEffect(() => {
    setUserInput((userInput) => ({
      ...userInput,
      image: null,
    }));
  }, []);

  useEffect(() => {
    if (guardian === "father") {
      setUserInput((userInput) => ({
        ...userInput,
        guardian_name: userInput?.father_name,
        guardian_occupation: userInput?.father_occupation,
        guardian_phone: userInput?.father_phone,
        staying_with_guardian:userInput?.father_alive
      }));
    }

    if (guardian === "mother") {
      setUserInput((userInput) => ({
        ...userInput,
        guardian_name: userInput?.mother_name,
        guardian_occupation: userInput?.mother_occupation,
        guardian_phone: userInput?.mother_phone,
        staying_with_guardian:userInput?.mother_alive
      }));
    }
  }, [guardian]);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
      image: null,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post("/api/v1/student/register", userInput)
      .then((response) => {
        if (response?.data?.status === 200) {
          setStudent(response?.data?.data);
          setScreen("guardian_info");
        }
      })
      .catch((err) => {
        console?.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="card card-table table-height2">
      <div className="card-header card-header-margin d-flex justify-content-between">
        <h3 className="my-auto">Parents Information</h3>
      </div>
      <div className="card-body booking_card">
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 pad-0">
                <div className="row formtype">
                  <div>
                    <h4>Father's Information</h4>
                  </div>
                  <div className="form-cover p-3">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            <h4>Full Name *</h4>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="father_name"
                            value={userInput?.father_name}
                            onChange={handleUserInput}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            <h4>Occupation *</h4>
                          </label>
                          <input
                            type="text"
                            name="father_occupation"
                            required
                            value={userInput?.father_occupation}
                            onChange={handleUserInput}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            <h4>Telephone/Mobile Number *</h4>
                          </label>
                          <input
                            type="text"
                            name="father_phone"
                            value={userInput?.father_phone}
                            required
                            onChange={handleUserInput}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            <h4>Is father alive? *</h4>
                          </label>
                          <select
                            className="form-control"
                            name="father_alive"
                            value={userInput?.father_alive}
                            onChange={handleUserInput}
                            required
                          >
                            <option value="">-- Select Option--</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <h4>Mother's Information</h4>
                  </div>
                  <div className="form-cover p-3">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            <h4>Full Name *</h4>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="mother_name"
                            value={userInput?.mother_name}
                            onChange={handleUserInput}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            <h4>Occupation *</h4>
                          </label>
                          <input
                            type="text"
                            name="mother_occupation"
                            required
                            value={userInput?.mother_occupation}
                            onChange={handleUserInput}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            <h4>Telephone/Mobile Number *</h4>
                          </label>
                          <input
                            type="text"
                            name="mother_phone"
                            value={userInput?.mother_phone}
                            required
                            onChange={handleUserInput}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            <h4>Is mother alive? *</h4>
                          </label>
                          <select
                            className="form-control"
                            name="mother_alive"
                            value={userInput?.mother_alive}
                            onChange={handleUserInput}
                            required
                          >
                            <option value="">-- Select Option--</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="d-flex justify-content-between mt-4">
                      <div className="form-group form-row">
                        {userInput?.guardian_name === null && (
                          <select
                            className="form-control"
                            name="mother_alive"
                            value={guardian}
                            onChange={(e) => setGuardian(e.target.value)}
                            required
                          >
                            <option value="">-- Select Guardian--</option>
                            <option value="father">Father</option>
                            <option value="mother">Mother</option>
                            <option value="other">Other</option>
                          </select>
                        )}
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-secondary px-md-5 me-3"
                          onClick={() => setScreen("school_info")}
                        >
                          Previous
                        </button>
                        <Button
                          cssClasses={"btn btn-success px-md-5"}
                          buttonText="Save and Continue"
                          isloading={isloading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ParentsInformation;

import axios from "axios";
import React, { useState } from "react";
import Button from "../../../components/Button";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";

const RegistrationForm = ({
  errors,
  setErrors,
  setPage,
  setScreen,
  setStudent
}) => {
  const [phone, setPhone] = useState();
  const [isloading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState({
    voucher_code: "",
  });

  const handleUserInput = (e) => {
    setErrors("");
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!isValidPhoneNumber(phone)) {
      window.alert("Please Enter a valid phone number");
    }else{
      setLoading(true);
      axios
        .post("/api/v1/voucher/verify", {voucher_code:userInput?.voucher_code,phone:phone})
        .then((res) => {
          localStorage.setItem('reg_no',userInput?.voucher_code);
          setStudent(res?.data?.data);
          setScreen("picture-upload");
        })
        .catch((err) => {
          console.log(err?.response);
          if (err?.response?.status === 422) {
            setErrors(err?.response?.data?.errors);
          }
          if (err?.response?.status === 400) {
            alert(err?.response?.data?.error_msg);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="formtype">
        <div className="form-group">
          <label htmlFor="">
            <h4 className="text-nowrap">Enter Admission Code</h4>
          </label>
          <input
            type="text"
            minLength="10"
            maxLength="12"
            value={userInput?.index_number}
            placeholder="eg. 050301603322"
            name="voucher_code"
            onChange={handleUserInput}
            required
            className="form-control text-left"
          />
          <small className="text-danger">{errors?.voucher_code}</small>
        </div>
        <div className="form-group">
          <label htmlFor="">
            <h4>Phone Number</h4>
          </label>
          <PhoneInput
            country="GH"
            defaultCountry="GH"
            // countries={["GH"]}
            countries={["GH"]}
            value={phone}
            onChange={setPhone}
            className="form-control text-left"
          />
          <div className="text-danger">
            {phone
              ? isValidPhoneNumber(phone)
                ? ""
                : "Invalid phone number"
              : ""}
          </div>
          <small className="text-danger">{errors?.phone}</small>
        </div>
      </div>

      <div className="mt-4">
        <Button
          cssClasses={"btn btn-success w-100"}
          isloading={isloading}
          buttonText={"Verify and Proceed"}
        />
      </div>
      <div className="form-group text-center">
        <botton
          className="btn btn-secondary mt-2 text-center"
          onClick={() => setPage("initial")}
        >
          Go Back
        </botton>
      </div>
    </form>
  );
};

export default RegistrationForm;

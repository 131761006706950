/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "./styles/authDesktop.css";
import "./styles/authMobile.css";
import "./styles/authTablet.css"

const SignUp = () => {
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    role: "admin",
    errors: [],
  });

  const [isSubmitting, setSubmitting] = useState(false);

  function handleUserInput(e) {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    const data = {
      name: userInput?.name,
      email: userInput?.email,
      phone: userInput?.phone,
      password: userInput?.password,
      role: userInput?.role,
    };

    axios.post("/api/v2/auth/signup", data).then((res) => {
      if (res?.data?.status === 200 && res?.data?.success === true) {
        Swal.fire({
          title: "Success",
          text: "Your Account Sign up is Successful and Pending Approval, Kindly Call/Whatsapp 0558571228 to speed up approval process. Thank You",
          icon: "success",
          confirmButtonColor: "#011535",
          confirmButtonText: "Okay",
        }).then((_) => {
          navigate("/login");
        });
      }
    });
  };

  return (
    <div className="container container-position">
      <div className="col-lg-12 pad-left">
        <div className="container-wrapper">
          <form onSubmit={handleSubmit}>
            <div className="p-4 d-flex flex-column h-100 justify-content-center">
              <h1 className="text-center my-5">Sign Up</h1>
              <div className="right d-flex flex-row position-relative mb-3">
                <input
                  type="text"
                  name="name"
                  value={userInput?.name}
                  onChange={handleUserInput}
                  className="flex-fill form-control"
                  required
                  placeholder="Full Name"
                  autoComplete="name"
                  autoFocus
                />
                <i className="far fa-user email position-absolute"></i>
              </div>
              <span className="text-danger text-center mt-1" role="alert">
                {userInput?.errors?.name}
              </span>

              <div className="right d-flex flex-row position-relative">
                <input
                  type="email"
                  className="flex-fill form-control"
                  name="email"
                  value={userInput?.email}
                  onChange={handleUserInput}
                  placeholder="Email"
                  required
                />
                <i className="far fa-envelope email position-absolute"></i>
              </div>
              <span className="text-danger text-center mt-1" role="alert">
                {userInput?.errors?.email}
              </span>

              <div className="left d-flex flex-row position-relative mt-4">
                <input
                  type="tel"
                  className="flex-fill form-control"
                  name="phone"
                  value={userInput?.phone}
                  onChange={handleUserInput}
                  required
                  autoComplete="current-password"
                  placeholder="phone"
                />
                <i className="fas fa-phone password position-absolute"></i>
              </div>
              <span className="text-danger mt-0" role="alert">
                {userInput?.errors?.phone}
              </span>

              <div className="left d-flex flex-row position-relative mt-4">
                <input
                  type="password"
                  className="flex-fill form-control"
                  name="password"
                  value={userInput?.password}
                  onChange={handleUserInput}
                  required
                  autoComplete="current-password"
                  placeholder="Password"
                />
                <i className="fas fa-lock password position-absolute"></i>
              </div>
              <span className="text-danger mt-0" role="alert">
                {userInput?.errors?.password}
              </span>

              <div className="d-flex flex-row justify-content-center mt-4">
                <Link to="/dashboard">
                  <button className="btn btn-primary px-5" type="button">
                    Sign up
                  </button>
                </Link>
              </div>

              <div className="d-flex flex-row justify-content-center mt-5">
                <Link to="/" className="action w-50 text-center">
                  Login Instead
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

import axios from "axios";
import React, { useState,useEffect } from "react";
import SunEditor from "suneditor-react";
import Swal from "sweetalert2";
import Button from "../../components/Button";

const AdmissionRequirements = ({ resource }) => {
  const [value, setValue] = useState(resource?.toString());
  const [isloading, setLoading] = useState(false);

  useEffect(() => {
    setValue(resource)
  }, [resource])

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/api/v1/school/resource/create", {
        admission_requirements: value,
        school_id: localStorage.getItem("school_id"),
      })
      .then((res) => {
        Swal.fire("Save", "Data has been saved successfully", "success");
      })
      .catch((err) => {
        console.log(err?.response);
        Swal.fire("Failed", "Error Saving Data", "error");
      })
      .finally((_) => {
        setLoading(false);
      });
  };

  return (
    <div className="card">
      <div className="card-header" id="headingOne">
        <div className="d-flex justify-content-between mb-2">
          <button
            className="btn btn-link btn-block text-left"
            type="button"
            data-toggle="collapse"
            data-target="#day"
            aria-expanded="false"
            aria-controls="day"
          >
            Mandatory Admission Requirements
          </button>

          <Button
            cssClasses={"btn btn-success"}
            buttonText={"Save Changes"}
            isloading={isloading}
            callback={handleSubmit}
          />
        </div>

        <div
          id="day"
          className="collapse"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div className="card-body">
             <SunEditor
              setContents={value}
              setOptions={{
                buttonList: [
                  // default
                  ["undo", "redo"],
                  ["bold", "underline", "italic", "list",'align'],
                  ["font", "fontSize", "italic", "list",'fontColor'],
                  ["table", "horizontalRule","link",'indent'],
                  ["formatBlock","paragraphStyle","fullScreen"],
                ],
              }}
              onChange={(value) => setValue(value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdmissionRequirements;

const BulkVoucherPurchase = () =>{
    return(
        <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row mt-5">
            <div className="col-md-12 d-flex">
              <div className="card card-table flex-fill">
                <div className="card-header d-flex justify-content-between">
                  <div>
                    <h4 className="card-title float-left mt-2">
                      Lists Of Bought Vouchers
                    </h4>
                  </div>
                  <div>
                    <button
                      className="btn btn-primary"
                    >
                      Buy Voucher
                    </button>
                  </div>
                </div>
                <div className="card-body booking_card">
                  <div className="d-flex justify-content-center align-content-center">
                    <div className="w-50 mb-2">
                      <input
                        type="text"
                        className="form-control text-center"
                        placeholder="Search for voucher"
                      />
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      className="table table-hover table-center"
                      id="example"
                    >
                      <thead>
                        <tr>
                          <th>Phone number</th>
                          <th className="text-center">Total Quantity</th>
                          <th className="text-center">Amount</th>
                          <th className="text-center">Remaining Quantity</th>
                          <th className="text-center">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[1,2,3,4,5,6,7,8].map((ele, i) => (
                          <tr key={i}>
                            <td className="text-nowrap">0554922935</td>
                            <td className="text-center">6</td>
                            <td className="text-nowrap text-center text-capitalize">¢23.00</td>
                            <td className="text-center">2</td>
                            <td className="text-center">27/03/22</td>
                          </tr>
                        ))}
                        {}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
export default BulkVoucherPurchase
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import Button from "../../components/Button";

const SchoolInformation = ({ setScreen, student, setStudent }) => {
  const [isLoading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState(student);
  

  useEffect(() => {
    setUserInput((userInput) => ({
      ...userInput,
      image: null,
    }));
  }, []);

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(()=>{
    setUserInput({...userInput,'reg_no':localStorage.getItem('reg_no'),image:null})
  },[])

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post("/api/v1/student/register", userInput)
      .then((response) => {
        if (response?.data?.status === 200) {
          setStudent(response?.data?.data);
          setScreen("parents_info");
        }
      })
      .catch((err) => {
        console?.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="card card-table table-height2">
      <div className="card-header card-header-margin d-flex justify-content-between">
        <h3 className="my-auto">School & Personal Information</h3>
      </div>
      <div className="card-body booking_card">
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 pad-0">
                <div className="row formtype">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>First Name *</h4>
                      </label>
                      <input
                        className="form-control text-left text-capitalize"
                        type="text"
                        value={student?.firstname}
                        name="firstname"
                        onChange={handleUserInput}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Last Name *</h4>
                      </label>
                      <input
                        className="form-control text-left"
                        type="text"
                        onChange={handleUserInput}
                        name="lastname"
                        value={student?.lastname}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Other Names</h4>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="othername"
                        value={userInput?.othername}
                        onChange={handleUserInput}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Gender *</h4>
                      </label>
                      <select
                        className="form-control"
                        name="gender"
                        value={userInput?.gender}
                        onChange={handleUserInput}
                        required
                      >
                        <option value="">---Select Gender---</option>
                        <option value={"male"}>Male</option>
                        <option value={"female"}>Female</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Date of Birth *</h4>
                      </label>
                      <input
                        type="date"
                        name="date_of_birth"
                        className="form-control"
                        required
                        value={student?.date_of_birth}
                        onChange={handleUserInput}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Place of Birth</h4>
                      </label>
                      <input
                        type="text"
                        name="place_of_birth"
                        onChange={handleUserInput}
                        value={student?.place_of_birth}
                        className="form-control text-capitalize"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Hometown *</h4>
                      </label>
                      <input
                        type="text"
                        name="hometown"
                        className="form-control"
                        onChange={handleUserInput}
                        value={student?.hometown}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Nationality *</h4>
                      </label>
                      <input
                        type="text"
                        name="nationality"
                        className="form-control"
                        onChange={handleUserInput}
                        value={student?.nationality}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Religion *</h4>
                      </label>
                      <select
                        className="form-control"
                        name="religious_denomination"
                        onChange={handleUserInput}
                        value={userInput?.religious_denomination}
                        required
                      >
                        <option value="">-- Select Option--</option>
                        <option value="christianity">Christianity</option>
                        <option value="ahmadi">Islam (Ahmadi)</option>
                        <option value="non-ahmadi">Islam (Non-Ahmadi)</option>
                        <option value="traditional">Traditional</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>Residence *</h4>
                      </label>
                      <input
                        type="text"
                        name="residence"
                        required
                        value={userInput?.residence}
                        onChange={handleUserInput}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        <h4>NHIS Nnumber</h4>
                      </label>
                      <input
                        type="text"
                        name="nhis"
                        value={userInput?.nhis}
                        onChange={handleUserInput}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group text-uppercase">
                      <label>
                        <h4>Class *</h4>
                      </label>
                      <input
                        type="text"
                        name="class"
                        required
                        value={userInput?.class}
                        onChange={handleUserInput}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <h4>Name Of Last School Attended *</h4>
                      </label>
                      <input
                        type="text"
                        name="prev_school"
                        required
                        value={userInput?.prev_school}
                        onChange={handleUserInput}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <h4>Reason For Leaving Last School *</h4>
                      </label>
                      <textarea
                        cols={5}
                        rows={5}
                        className={'form-control'}
                        name={"reason_for_leaving_prev_school"}
                        value={userInput?.reason_for_leaving_prev_school}
                        onChange={handleUserInput}
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="d-flex float-end">
                      <button
                        type="button"
                        className="btn btn-secondary px-md-5 me-3"
                        onClick={() => setScreen("picture-upload")}
                      >
                        Previous
                      </button>

                      <Button
                        cssClasses={"btn btn-success px-md-5"}
                        buttonText="Save and Continue"
                        isloading={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default SchoolInformation;

import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PagePagination from "../../../components/PagePagination";
import Card from "../partials/Card";
import SvgIcons from "../partials/SvgIcons";

const Home = () => {
  // /api/v1/admission/stats
  const [page,setPage]=useState(0);
  const [reload,setReload]=useState(false);
  const navigate=useNavigate();
  const handlePageClicked = (e) => {
    setPage(e.selected+1);
    setReload(!reload);
  };
  const [data,setData]=useState();
  const [search,setSearch]=useState('');

  useEffect(() => {
    axios
      .get(
        `/api/v1/admission/stats?page=${page}`
      )
      .then((res) => {
        if (res.data.status === 200) {
          setReload(true);
          setData(res?.data?.data);
        }
      })
      .catch((err) => {
        setReload(true);
        console.log(err?.response);
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  }, [reload,navigate,page]);

  // const handleExport=(e)=>{
  //   e.preventDefault();
  //   axios.get('/api/v1/admission/export')
  //   .then(res=>{
  //     console.log(res);
  //   })
  // }

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12 mt-5">
              <h3 className="page-title mt-3 text-capitalize">{localStorage.getItem('school')}</h3>
            </div>
          </div>
        </div>

        <div className="row">
          <Card title="Total Admissions" value={data?.total_admitted || 0} svg={SvgIcons.awards} />
          <Card title="Wallet Balance" value={data?.total_balance?.deposit || 0} svg={SvgIcons.awards} />
          <Card title="SMS Balance" value={data?.total_unregistered || 0} svg={SvgIcons.awards} />
        </div>

        <div className="row">
          <div className="col-md-12 d-flex">
            <div className="card card-table flex-fill">
              <div className="card-header d-flex flex-row justify-content-between">
                <h4 className="card-title float-left mt-2">Lists Of Admitted Students</h4>
                <div className="d-flex flex-row gap-3">
                <input type="text" className="form-control" value={search} onChange={(e)=>setSearch(e.target.value)}/> 
                <a href="https://peniel.farmeraidgh.com/api/v1/admission/export"  without rel="noreferrer" target={'_blank'} className="btn btn-success text-nowrap" >Export Data</a>
                </div>
              </div>
              <div className="card-body booking_card">
                <div className="table-responsive">
                  <table className="table table-hover table-center" id="example">
                    <thead>
                      <tr>
                        <th>Reg_no</th>
                        <th className="text-center">Full Name</th>
                        <th className="text-center">Class</th>
                        <th className="text-center">Gender</th>
                        <th className="text-center">Guardian Contact</th>
                        <th className="text-center">Admission Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.students?.filter(item=>{
                        if(search!==""){
                          const fullname=item?.firstname+" "+item?.othername+" "+ item?.lastname
                          return fullname?.toLocaleLowerCase()?.includes(fullname?.toLocaleLowerCase())&& item?.firstname!==null
                        }
                       return item?.firstname!==null
                      })?.map((item, i) => (
                        <tr key={i}>
                          <td className="text-nowrap text-capitalize">{item?.reg_no}</td>
                          <td className="text-nowrap text-capitalize">{`${item?.firstname} ${item?.othername||''} ${item?.lastname}`}</td>
                          <td className="text-center">{item?.class}</td>
                          <td className="text-center text-capitalize">{item?.gender}</td>
                          <td className="text-center">{item?.guardian_phone}</td>
                          <td className="text-center">{item?.admission_date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <PagePagination pageCount={data?.registered_list?.last_page} handlePageClick={handlePageClicked}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;